<template>
  <div>
    <b-table
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      :items="vorgangData"
      :fields="vorgangFields"
      :busy="isVorgangLoading"
      responsive
      hover
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template v-slot:cell(zeitraum)="row">
        <div style="width: 150px">
          {{ formatDateWithoutHours(row.item.reisedatum) }} -
          {{ formatDateWithoutHours(row.item.enddatum) }}
        </div>
      </template>
      <template v-slot:cell(kundenname)="row">
        <div style="width: 170px">
          {{ kundenName(row.item) }}
        </div>
      </template>
      <template v-slot:cell(blankState)="row">
        <VorgangStateWidget v-if="row.item?.blankState" :state="row.item?.blankState" />
      </template>
      <template v-slot:cell(released)="row">
        <i :class="row.item.isReleased ? 'fas fa-check text-success' : 'fas fa-times text-danger'"></i>
      </template>
      <template v-slot:cell(fehlendePassdaten)="row">
        <div v-if="!isVorgangLoading && teilnehmerPassdatenRequirements != null">
          <div
            v-if="teilnehmerPassdatenRequirements[row.item.id]?.length > 0"
            :id="'tooltip-requirements' + row.item.id"
            style="cursor: pointer"
          >
            <i class="fas fa-check text-success"></i>
          </div>
          <div v-else><i class="fas fa-times text-danger"></i></div>
          <b-tooltip
            :target="'tooltip-requirements' + row.item.id"
            triggers="hover"
            placement="left"
            custom-class="custom-tooltip"
          >
            <div
              v-for="teilnehmerWithRequirements in teilnehmerPassdatenRequirements[row.item.id]"
              :key="teilnehmerWithRequirements.name"
            >
              <p>
                <b>{{ teilnehmerWithRequirements.kunde }}</b>
              </p>
              <ul class="text-left">
                <li
                  v-for="missingField in teilnehmerWithRequirements.missingFields"
                  :key="teilnehmerWithRequirements.kunde + '-' + missingField"
                >
                  {{ passdatenFormatter[missingField] }}
                </li>
              </ul>
            </div>
          </b-tooltip>
        </div>
      </template>
      <template v-slot:cell(aufgaben)="row">
        <b-badge :id="'tooltip-target-vorgang' + row.item.id">
          {{ row.item.aufgaben?.length }}
        </b-badge>
        <b-tooltip
          :target="'tooltip-target-vorgang' + row.item.id"
          triggers="hover"
          custom-class="custom-tooltip"
          v-if="row.item.aufgaben?.length > 1"
        >
          <AufgabenWidget
            :openAufgabenId="openAufgabenId"
            class="mt-3"
            :ansicht="'aufgabe-details'"
            :aufgaben="row.item.aufgaben || []"
          ></AufgabenWidget>
        </b-tooltip>
      </template>
    </b-table>
  </div>
</template>
<script>
import { format } from 'date-fns';
import VorgangStateWidget from '@/components/vorgaenge/vorgang-state-widget.vue';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import apiService from '@/core/common/services/api.service';

export default {
  components: { VorgangStateWidget, AufgabenWidget },
  props: {
    vorgangData: { type: Array, required: true },
    openAufgabenId: { type: Number, default: null },
    gueltigeKonfiguration: { type: Object, required: true },
  },
  data() {
    return {
      isVorgangLoading: false,
      vorgangDataExpanded: [],
      teilnehmerPassdatenRequirements: null,
      vorgangFields: [
        {
          tdClass: 'align-middle',
          key: 'id',
          label: 'BNR',
        },
        {
          tdClass: 'align-middle',
          key: 'pnr',
          label: 'PNR',
          formatter: value => value?.join(','),
        },
        {
          tdClass: 'align-middle',
          key: 'kundenname',
        },
        {
          tdClass: 'align-middle',
          key: 'zimmer',
          formatter: value => value.join(', '),
        },
        {
          tdClass: 'align-middle',
          key: 'npx',
          label: 'Pax',
        },
        {
          tdClass: 'align-middle',
          key: 'blankState',
          label: 'Buchungsstatus',
        },
        {
          tdClass: 'align-middle',
          key: 'released',
        },
        { tdClass: 'align-middle', key: 'fehlendePassdaten', label: 'Passdaten fehlen' },
        { tdClass: 'align-middle', key: 'aufgaben', label: 'Aufgaben' },
      ],
      passdatenFormatter: {
        name: 'Name',
        geburtsdatum: 'Geburtsdatum',
        geburtsort: 'Geburtsort',
        geburtsland: 'Geburtsland',
        nationalitaet: 'Nationalität',
        geschlecht: 'Geschlecht',
        passName: 'Passname',
        passLand: 'Passland',
        passNummer: 'Passnummer',
        passOrt: 'Passort',
        passArt: 'Passart',
        passAusstellungsdatum: 'Passausstellungsdatum',
        passGueltigkeitsdatum: 'Passgültigkeitsdatum',
      },
    };
  },
  mounted() {
    this.loadAllTeilnehmer();
  },
  computed: {
    fehlendePassdaten() {
      if (
        this.gueltigeKonfiguration == null &&
        this.vorgangData != null &&
        this.vorgangData.teilnehmer.length > 0
      )
        return null;

      return false;
    },
  },
  methods: {
    kundenName(item) {
      if (item?.kunde?.master) {
        return item?.kunde?.master.vorname + ' ' + item?.kunde?.master.nachname;
      } else return '';
    },
    formatDateWithoutHours(date) {
      if (date) {
        return format(new Date(date), 'dd.MM.yyyy');
      } else return '';
    },
    rowClicked(item) {
      //   window.open('/#/reisen/' + item.reisekuerzel + '/edit', '_blank');
    },
    async loadAllTeilnehmer() {
      this.isVorgangLoading = true;
      await Promise.all(
        this.vorgangData.map(vorgang =>
          this.getVorgangTeilnehmerPassdatenRequirementsById(
            vorgang.id,
            vorgang.hotelleistungen[0].reiseterminId
          )
        )
      ).then(result => {
        this.teilnehmerPassdatenRequirements = result.flat().reduce((acc, curr) => {
          if (curr.bnr in acc) {
            acc[curr.bnr] = [
              ...acc[curr.bnr],
              {
                missingFields: Object.entries(curr)
                  .filter(item => item[1]?.required && !item[1]?.provided)
                  .map(item => item[0]),
                kunde: curr.name,
              },
            ];
            return acc;
          }
          acc[curr.bnr] = [
            {
              missingFields: Object.entries(curr)
                .filter(item => item[1]?.required && !item[1]?.provided)
                .map(item => item[0]),
              kunde: curr.name,
            },
          ];
          return acc;
        }, {});
      });
      this.isVorgangLoading = false;
    },
    getTeilnehmerPassdatenRequirements(vorgangId) {
      if (this.teilnehmerPassdatenRequirements == null) return null;

      return this.teilnehmerPassdatenRequirements.filter(data => data.bnr === vorgangId);
    },
    getRequiredMissingData(vorgangId) {
      if (this.teilnehmerPassdatenRequirements == null) return null;

      return this.teilnehmerPassdatenRequirements
        .filter(data => data.bnr === vorgangId)
        .reduce((acc, curr) => {
          const missingRequirements = [];

          for (const [key, value] of Object.entries(curr)) {
            if (value?.required && !value?.provided) {
              missingRequirements.push(key);
            }
          }
          if (missingRequirements.length > 0) acc.push({ [curr.name]: missingRequirements });
          return acc;
        }, []);
    },
    getVorgangTeilnehmerPassdatenRequirementsById(vorgangId, reiseterminId) {
      return apiService.get(`Hotelleistung/${vorgangId}/${reiseterminId}/teilnehmer`).then(({ data }) => {
        return (
          (data.result &&
            data.result.teilnehmer &&
            data.result.teilnehmer.filter(teilnehmer => !teilnehmer.isDeleted)) ||
          []
        );
      });
    },
  },
};
</script>
<style scoped>
:deep(.custom-tooltip) > .tooltip-inner {
  max-width: none;
}
</style>
