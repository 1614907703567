<template>
  <div>
    <div v-if="isFomAufgabe">
      <VorgangVerlinkungenFom :vorgangData="vorgangData" :openAufgabenId="openAufgabenId" />
    </div>
    <div v-else>
      <VorgangVerlinkungenBasic
        :vorgangData="vorgangData"
        :openAufgabenId="openAufgabenId"
        :gueltigeKonfiguration="gueltigeKonfiguration"
      />
    </div>
  </div>
</template>
<script>
import VorgangVerlinkungenBasic from '@/components/aufgaben/verlinkungen/vorgang-verlinkungen-basic.vue';
import VorgangVerlinkungenFom from '@/components/aufgaben/verlinkungen/vorgang-verlinkungen-fom.vue';

export default {
  components: {
    VorgangVerlinkungenBasic,
    VorgangVerlinkungenFom,
  },
  props: {
    vorgangData: { type: Array, required: true },
    isFomAufgabe: { type: Boolean, required: true },
    openAufgabenId: { type: Number, default: null },
    gueltigeKonfiguration: { type: Object },
  },
};
</script>
